
import { Component, Prop, Vue } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';
import { BTable } from 'bootstrap-vue';
import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import { Coupon } from '@/types';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';

@Component({
  mixins: [QueryParamsPaginationMixin],

  components: {
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
  },
})
export default class CouponsList extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  isLoading = false;
  isLoaded = false;
  sortBy = null;
  sortDesc = false;
  total = 0;
  fields = [
    { label: '', key: 'checkbox', class: 'text-right' },
    '#',
    { label: 'Κωδικός', key: 'code', sortable: true },
    { label: 'Αξία', key: 'value', class: 'text-center', sortable: true },
    {
      label: 'Αριθμός Παραγγελιών',
      key: 'orders_count',
      class: 'text-center',
      sortable: true,
    },
    { label: 'Ενεργό', key: 'active', class: 'text-center' },
    { key: 'actions', label: '', class: 'text-right' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Κουπόνια',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Ενεργό',
      apiName: 'active',
      type: 'boolean',
      value: false,
    },
  ];
  items = [];
  searchTerm = '';
  selectedIds = [];

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async couponsProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/coupons', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.coupons.total;
      this.items = data.coupons.data;
      this.isLoaded = true;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα άρθρα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete(item: Coupon) {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε το κουπόνι: ${item.code};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/coupons/${item.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Το κουπόνι ${item.code} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Το κουπόνι ${item.code} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο κουπόνι;'
          : `τα επιλεγμένα ${this.selectedIds.length} κουπόνια;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete('/coupons', {
            params: {
              ids: this.selectedIds,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: 'comma',
                encode: false,
              });
            },
          }),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το κουπόνι διαγράφηκε επιτυχώς'
                  : `${this.selectedIds.length} κουπόνια διαγράφηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το κουπόνι δεν διεγράφη'
                : 'Τα κουπόνια δεν διεγράφησαν'
            }`,
            icon: 'error',
            timer: 3000,
          });
        }
      }
    });
  }

  handleMassActivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο κουπόνι;'
          : `τα επιλεγμένα ${this.selectedIds.length} κουπόνια;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            '/coupons',
            {},
            {
              params: {
                active: true,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το κουπόνι ενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} κουπόνια ενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το κουπόνι δεν ενεργοποιήθηκε'
                : 'Τα κουπόνια δεν ενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassDeactivate() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να απενεργοποιήσετε ${
        this.selectedIds.length === 1
          ? 'το επιλεγμένο κουπόνι;'
          : `τα επιλεγμένα ${this.selectedIds.length} κουπόνια;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.patch(
            '/coupons',
            {},
            {
              params: {
                active: false,
                ids: this.selectedIds,
              },
              paramsSerializer: params => {
                return qs.stringify(params, {
                  arrayFormat: 'comma',
                  encode: false,
                });
              },
            },
          ),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'Το κουπόνι απενεργοποιήθηκε επιτυχώς'
                  : `${this.selectedIds.length} κουπόνια απενεργοποιήθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Το κουπόνι δεν απενεργοποιήθηκε'
                : 'Τα κουπόνια δεν απενεργοποιήθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
          });
        }
      }
    });
  }

  async handleToggleActive(item: Coupon) {
    try {
      await request.patch(`/coupons/${item.id}/active`);

      this.$swal({
        title: 'Επιτυχία',
        text: `Το κουπόνι ${
          item.active ? 'απενεργοποιήθηκε' : 'ενεργοποιήθηκε'
        }!`,
        icon: 'success',
        timer: 3000,
      });

      this.$nextTick(() => {
        this.$refs.table && (this.$refs.table as BTable).refresh();
      });
    } catch (error) {
      this.$swal({
        title: 'Σφάλμα',
        text: `Αδυναμία ${
          item.active ? 'απενεργοποίησης' : 'ενεργοποίησης'
        } κουπονιού`,
        icon: 'error',
        timer: 3000,
      });
    }
  }
}
