<template>
  <BOverlay :show="isLoading" rounded="sm" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown
          size="sm"
          right
          data-test="product-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <slot name="extra-actions" />

          <BDropdownItemButton class="delete-btn" @click="handleDelete">
            Διαγραφή προϊόντος
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left mb-5">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <ValidationProvider name="title">
                <BFormGroup size="md" label="Τίτλος">
                  <BFormInput
                    id="title"
                    v-model="form.title"
                    type="text"
                    placeholder="Εισάγετε το τίτλο"
                    data-test="title"
                    autofocus
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="description">
                <BFormGroup size="md" label="Περιγραφή">
                  <BFormTextarea
                    id="description"
                    v-model="form.description"
                    type="text"
                    placeholder="Εισάγετε περιγραφή"
                    data-test="description"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="keywords">
                <BFormGroup size="md" label="Keywords">
                  <BFormTextarea
                    id="keywords"
                    v-model="form.keywords"
                    type="text"
                    placeholder="Εισάγετε τα keywords"
                    data-test="keywords"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="image" rules="url" v-slot="{ errors }">
                <BFormGroup size="md" label="Εικόνα">
                  <BFormTextarea
                    id="image"
                    v-model="form.image"
                    type="text"
                    placeholder="Εισάγετε το URL της εικόνας"
                    data-test="image"
                  />
                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε έγκυρο URL
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="schema"
                rules="json"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Schema">
                  <codemirror v-model="form.schema" />
                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε έγκυρο JSON
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="og_title">
                <BFormGroup size="md" label="Open Graph τίτλος">
                  <BFormTextarea
                    id="og_title"
                    v-model="form.og_title"
                    type="text"
                    placeholder="Εισάγετε τίτλο"
                    data-test="og_title"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="og_description">
                <BFormGroup size="md" label="Open Graph περιγραφή">
                  <BFormTextarea
                    id="og_description"
                    v-model="form.og_description"
                    type="text"
                    placeholder="Εισάγετε περιγραφή"
                    data-test="og_description"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider
                name="og_image"
                rules="url"
                v-slot="{ errors }"
              >
                <BFormGroup size="md" label="Open Graph εικόνα">
                  <BFormTextarea
                    id="og_image"
                    v-model="form.og_image"
                    type="text"
                    placeholder="Εισάγετε το url της εικόνας"
                    data-test="og_image"
                  />
                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε έγκυρο URL
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="og_type">
                <BFormGroup size="md" label="Open Graph τύπος">
                  <BFormInput
                    id="og_type"
                    v-model="form.og_type"
                    type="text"
                    placeholder="Εισάγετε τύπο"
                    data-test="og_type"
                  />
                </BFormGroup>
              </ValidationProvider>

              <ValidationProvider name="og_url" rules="url" v-slot="{ errors }">
                <BFormGroup size="md" label="Open Graph public URL">
                  <BFormInput
                    id="og_url"
                    v-model="form.og_url"
                    type="text"
                    placeholder="Εισάγετε URL"
                    data-test="og_url"
                  />
                  <BFormText
                    text-variant="danger"
                    data-test="title-error"
                    v-if="errors[0]"
                  >
                    Παρακαλώ εισάγετε έγκυρο URL
                  </BFormText>
                </BFormGroup>
              </ValidationProvider>
            </BCardBody>

            <BCardFooter class="sticky-footer">
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>

                <BCol class="d-flex align-self-center justify-content-end">
                  <a
                    :href="fullHrefPath"
                    class="d-flex align-items-center"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Προβολή στο e-shop

                    <BIcon icon="chevron-right" class="ml-1" />
                  </a>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick } from 'lodash';

import request from '@/utils/request';
import BaseButtonSubmit from '@/components/BaseButtonSubmit';
import Layout from './components/ProductsEditLayout';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

export default {
  mixins: [ProductsEditMethodsMixin],
  components: {
    BaseButtonSubmit,
    Layout,
  },

  data: () => ({
    meta: {},
    model: {},
    form: {},
    isSaving: false,
    isLoading: false,
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Προϊόντα',
        to: { name: 'products' },
      },
      {
        text: 'Επεξεργασία',
        to: { name: 'products.edit.general' },
      },
      {
        text: 'SEO',
        active: true,
      },
    ],
  }),

  async created() {
    try {
      this.isLoading = true;
      const [metaData, productData] = await Promise.all([
        request.get(`/products/${this.$route.params.id}/meta`),
        request.get(`/products/${this.$route.params.id}`),
      ]);

      this.model = productData.data.product;
      this.meta = metaData.data.meta ?? {};
      this.form = pick(this.meta, [
        'title',
        'description',
        'keywords',
        'image',
        'schema',
        'og_title',
        'og_description',
        'og_image',
        'og_type',
        'og_url',
      ]);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθούν τα meta tags του προϊόντος',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(`/products/${this.$route.params.id}/meta`, this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα meta tags του προϊόντος ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        this.$emit('get-counters');
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα meta tags του προϊόντος δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = pick(this.meta, [
        'title',
        'description',
        'keywords',
        'image',
        'schema',
        'og_title',
        'og_description',
        'og_image',
        'og_type',
        'og_url',
      ]);
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
