<template>
  <BOverlay :show="isLoading" no-fade>
    <Layout :breadcrumb-items="breadcrumbItems">
      <template #actions>
        <BDropdown
          size="sm"
          right
          data-test="product-actions"
          variant="primary"
        >
          <template v-slot:button-content class="btn-primary">
            Ενέργειες
            <BIcon icon="chevron-down" class="font-size-sm ml-1" />
          </template>

          <slot name="extra-actions" />

          <BDropdownItem v-if="canAddProducts" :to="{ name: 'products.add' }">
            Προσθήκη προϊόντος
          </BDropdownItem>

          <BDropdownDivider v-if="canAddProducts" />

          <BDropdownItemButton class="delete-btn" @click="handleDelete">
            Διαγραφή προϊόντος
          </BDropdownItemButton>
        </BDropdown>
      </template>

      <BCard no-body class="main-card no-border-top-left">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(handleForm)"
            novalidate
            data-test="form"
          >
            <BCardBody>
              <div class="row">
                <div class="col">
                  <ValidationProvider name="buy_price">
                    <BFormGroup label="Τιμή Αγοράς">
                      <BFormInput
                        :disabled="!canEditProductBuyPrice"
                        id="buy_price"
                        v-model.number="form.buy_price"
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="Εισάγετε την τιμή αγοράς του προϊόντος"
                        data-test="buy_price"
                      />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="sell_price">
                    <BFormGroup label="Προτεινόμενη Λιανική Τιμή *">
                      <BFormInput
                        id="sell_price"
                        :disabled="!canEditProductSellPrice"
                        v-model.number="form.sell_price"
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="Εισάγετε την προτεινόμενη λιανική τιμή του προϊόντος"
                        @keyup="handleSellPriceChange"
                      />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <ValidationProvider name="discount">
                    <BFormGroup class="mb-0" label="Έκπτωση (%) *">
                      <BFormInput
                        id="discount"
                        v-model.number="form.discount"
                        type="number"
                        min="0"
                        max="100"
                        step="0.01"
                        placeholder="Εισάγετε την έκπτωση του προϊόντος"
                        data-test="discount"
                        @keyup="handleDiscountChange"
                      />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
                <div class="col">
                  <ValidationProvider name="final_price">
                    <BFormGroup class="mb-0" label="Τελική Τιμή *">
                      <BFormInput
                        id="final_price"
                        v-model.number="form.final_price"
                        type="number"
                        min="0"
                        step="0.01"
                        placeholder="Εισάγετε την τελική τιμή του προϊόντος"
                        data-test="final_price"
                        @keyup="handleFinalPriceChange"
                      />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider name="allow_prices_sync_updates">
                    <BFormGroup size="md" label="Αυτόματη Ενημέρωση Τιμών">
                      <BFormCheckbox
                        disabled
                        v-model="form.allow_prices_sync_updates"
                        switch
                      />
                    </BFormGroup>
                  </ValidationProvider>
                </div>
              </div>
            </BCardBody>

            <BCardFooter>
              <BRow class="justify-content-end">
                <BCol>
                  <BaseButtonSubmit
                    variant="primary"
                    icon="check-circle"
                    :isSaving="isSaving"
                    title="Αποθήκευση"
                  />

                  <BButton
                    class="ml-3"
                    @click="handleReset"
                    data-test="reset-button"
                  >
                    <BIcon icon="arrow-counterclockwise" />
                    Επαναφορά
                  </BButton>
                </BCol>

                <BCol class="d-flex align-self-center justify-content-end">
                  <a
                    :href="fullHrefPath"
                    class="d-flex align-items-center"
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    Προβολή στο e-shop

                    <BIcon icon="chevron-right" class="ml-1" />
                  </a>
                </BCol>
              </BRow>
            </BCardFooter>
          </BForm>
        </ValidationObserver>
      </BCard>
    </Layout>
  </BOverlay>
</template>

<script>
import { pick, round, isNaN } from 'lodash';
import request from '@/utils/request';
import Layout from './components/ProductsEditLayout';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

export default {
  mixins: [ProductsEditMethodsMixin],
  components: {
    Layout,
  },

  data() {
    return {
      eshopURL: process.env.VUE_APP_ESHOP_URL,
      brands: [],
      isLoading: false,
      isSaving: false,
      model: {},
      form: {
        buy_price: 0,
        sell_price: 0,
        final_price: 0,
        discount: 0,
        allow_prices_sync_updates: true,
      },
      breadcrumbItems: [
        {
          text: 'Αρχική',
          to: { name: 'home' },
        },
        {
          text: 'Προϊόντα',
          to: { name: 'products' },
        },
        {
          text: 'Επεξεργασία',
          to: { name: 'products.edit.general' },
        },
        {
          text: 'Τιμές',
          active: true,
        },
      ],
    };
  },

  async created() {
    try {
      this.isLoading = true;
      const { data } = await request.get(`/products/${this.$route.params.id}`);

      this.model = data.product;
      this.form = pick(this.model, [
        'buy_price',
        'sell_price',
        'final_price',
        'discount',
        'allow_prices_sync_updates',
      ]);

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσε να φορτωθεί το προϊόν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    canEditProductBuyPrice() {
      return process.env.VUE_APP_CAN_EDIT_PRODUCT_BUY_PRICE === 'true';
    },

    canEditProductSellPrice() {
      return process.env.VUE_APP_CAN_CAN_EDIT_PRODUCT_SELL_PRICE === 'true';
    },
  },

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.put(
          `/products/${this.$route.params.id}/prices`,
          this.form,
        );

        this.$swal({
          title: 'Επιτυχία',
          text: 'Τα στοιχεία του προϊόντος ενημερώθηκαν',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Τα στοιχεία του προϊόντος δεν ενημερώθηκαν',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = pick(this.model, [
        'buy_price',
        'sell_price',
        'final_price',
        'discount',
        `allow_prices_sync_updates`,
      ]);
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    handleSellPriceChange({ target: { value } }) {
      const val = parseFloat(value);

      if (!isNaN(val)) {
        this.form = {
          ...this.form,
          final_price: round((val * (100 - this.form.discount)) / 100, 2),
        };
      }
    },

    handleDiscountChange({ target: { value } }) {
      const val = parseFloat(value);

      if (!isNaN(val)) {
        this.form = {
          ...this.form,
          final_price: round((this.form.sell_price * (100 - val)) / 100, 2),
        };
      }
    },

    handleFinalPriceChange({ target: { value } }) {
      const val = parseFloat(value);

      if (!isNaN(val)) {
        this.form = {
          ...this.form,
          discount: round(
            ((this.form.sell_price - val) / this.form.sell_price) * 100,
            2,
          ),
        };
      }
    },
  },
};
</script>
