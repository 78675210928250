import { render, staticRenderFns } from "./OrdersStatusesItem.vue?vue&type=template&id=088bd405&scoped=true"
import script from "./OrdersStatusesItem.vue?vue&type=script&lang=ts"
export * from "./OrdersStatusesItem.vue?vue&type=script&lang=ts"
import style0 from "./OrdersStatusesItem.vue?vue&type=style&index=0&id=088bd405&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088bd405",
  null
  
)

export default component.exports