<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        Τρόποι πληρωμής
        <BBadge
          v-if="isLoaded"
          class="align-middle font-size-ms px-2 py-1 ml-2"
          variant="secondary"
          pill
        >
          {{ total }}
        </BBadge>
      </div>

      <BButton
        variant="primary"
        :to="{ name: 'settings.checkout-methods.add' }"
        size="sm"
        class="d-inline-flex align-items-center"
      >
        <BIcon icon="plus-circle" class="mr-2" /> Προσθήκη
      </BButton>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <BCardBody>
        <BTable
          hover
          bordered
          striped
          no-sort-reset
          sort-icon-left
          responsive="md"
          id="table"
          ref="table"
          :items="checkoutMethodsProvider"
          :current-page="currentPage"
          :fields="fields"
          :filter="searchTerm"
          :busy="isLoading"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          show-empty
          data-test="checkout-methods-list"
        >
          <template v-slot:head(checkbox)>
            <BFormCheckbox
              v-model="allSelected"
              :indeterminate="indeterminate"
              class="text-left"
              @change="toggleAll"
            />
          </template>

          <template v-slot:cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(title)="data">
            <RouterLink
              :to="{
                name: 'settings.checkout-methods.edit',
                params: { id: data.item.id },
              }"
              class="text-decoration-none"
            >
              {{ data.item.title }}
            </RouterLink>
          </template>

          <template v-slot:cell(active)="data">
            <BFormCheckbox
              :checked="data.item.active"
              switch
              @change="handleToggleActive(data.item)"
            />
          </template>

          <template v-slot:cell(actions)="data">
            <ListThreeDotsMenu
              :item="data.item"
              route-name="settings.checkout-methods.edit"
              :can-delete="false"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center my-4">
              <div class="mb-4">
                <BSpinner class="align-middle" />
              </div>
              <h5>Λήψη δεδομένων...</h5>
            </div>
          </template>

          <template v-slot:empty>
            <h5 class="mb-0 py-2 text-center">Δεν βρέθηκαν τρόποι πληρωμής</h5>
          </template>

          <template v-slot:emptyfiltered>
            <div class="mb-0 py-2 text-center">
              <h2 class="text-center mb-2">
                <BIcon icon="search" />
              </h2>
              Δεν βρέθηκαν αποτελέσματα αναζήτησης για "{{ searchTerm }}"
            </div>
          </template>
        </BTable>
      </BCardBody>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import PaginationMixin from '@/mixins/PaginationMixin';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu';
import CheckoutMethodsMixins from './mixins/CheckoutMethodsMixins';

export default {
  mixins: [PaginationMixin, CheckoutMethodsMixins],

  components: {
    ListThreeDotsMenu,
  },

  data: () => ({
    sortBy: 'title',
    sortDesc: false,
    fields: [
      '#',
      { label: 'Όνομα', key: 'title', sortable: true, class: 'w-75' },
      { label: 'Ενεργό', key: 'active', class: 'text-right' },
      { label: '', key: 'actions', class: 'text-right' },
    ],
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Τρόποι πληρωμής',
        active: true,
      },
    ],
  }),

  methods: {
    async checkoutMethodsProvider(ctx) {
      try {
        this.isLoading = true;

        const { data } = await request.get('/checkout-methods', {
          params: {
            page: ctx.currentPage,
            size: ctx.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            filter: ctx.filter,
          },
        });

        this.currentPage = 1;
        this.total = data.checkoutMethods.length;
        this.items = data.checkoutMethods;
        this.isLoaded = true;

        return this.items;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι τρόποι πληρωμής',
          icon: 'error',
          timer: 3000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
