<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex border-top">
      <div class="tab-header">
        Νέα Ερώτηση
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <BForm
          @submit.prevent="handleSubmit(handleForm)"
          novalidate
          data-test="form"
        >
          <BCardBody>
            <ValidationProvider
              name="question"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup size="md" label="Ερώτηση *">
                <BTextarea
                  v-model="form.question"
                  name="question"
                  data-test="question"
                  :rows="2"
                />

                <BFormText
                  text-variant="danger"
                  data-test="title-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε την ερώτηση
                </BFormText>
              </BFormGroup>
            </ValidationProvider>

            <ValidationProvider
              name="answer"
              rules="required"
              v-slot="{ errors }"
            >
              <BFormGroup label="Απάντηση *" class="mb-0">
                <BTextarea
                  v-model="form.answer"
                  name="answer"
                  data-test="question"
                  :rows="8"
                />

                <BFormText
                  text-variant="danger"
                  data-test="slug-error"
                  v-if="errors[0]"
                >
                  Παρακαλώ εισάγετε την απάντηση
                </BFormText>
              </BFormGroup>
            </ValidationProvider>
          </BCardBody>

          <BCardFooter>
            <BRow class="justify-content-end">
              <BCol>
                <BaseButtonSubmit
                  variant="primary"
                  icon="check-circle"
                  :isSaving="isSaving"
                  title="Αποθήκευση"
                />

                <BButton
                  class="ml-3"
                  @click="handleReset"
                  data-test="reset-button"
                >
                  <BIcon icon="arrow-counterclockwise" />
                  Επαναφορά
                </BButton>
              </BCol>
            </BRow>
          </BCardFooter>
        </BForm>
      </ValidationObserver>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';

export default {
  mixins: [EditorMixin],

  data: () => ({
    isSaving: false,
    form: {
      question: '',
      answer: '',
    },
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Συχνές Ερωτήσεις',
        to: { name: 'content.questions' },
      },
      {
        text: 'Προσθήκη',
        active: true,
      },
    ],
  }),

  methods: {
    async handleForm() {
      try {
        this.isSaving = true;
        await request.post('/questions', this.form);

        this.$swal({
          title: 'Επιτυχία',
          text: 'Η ερώτηση προστέθηκε',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });

        await this.$router.push({ name: 'content.questions' });
      } catch (err) {
        const { status, data } = err.response;
        if (status === 400) {
          this.$refs.form.setErrors(data.messages);
        }

        this.$swal({
          title: 'Σφάλμα',
          text: 'Αδυναμία προσθήκης ερώτησης',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isSaving = false;
      }
    },

    handleReset() {
      this.form = {
        question: '',
        answer: '',
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>
