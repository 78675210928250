<template>
  <div>
    <BBreadcrumb :items="breadcrumbItems" />

    <div class="d-flex justify-content-between align-items-center border-top">
      <div class="tab-header">
        Λίστα Στατικών Σελίδων
      </div>
    </div>

    <BCard no-body class="main-card no-border-top-left">
      <BCardBody>
        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mb-3"
        />

        <BTable
          hover
          bordered
          striped
          no-sort-reset
          sort-icon-left
          responsive="md"
          id="table"
          ref="table"
          :items="pagesProvider"
          :current-page="currentPage"
          :fields="fields"
          :filter="searchTerm"
          :busy="isLoading"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :tbody-tr-class="rowClass"
          show-empty
          data-test="pages-list"
        >
          <template v-slot:cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(title)="data">
            <RouterLink
              :to="{
                name: 'content.pages.edit',
                params: { slug: data.item.slug },
              }"
              class="text-decoration-none"
            >
              {{ data.item.title }}
            </RouterLink>
          </template>

          <template v-slot:table-busy>
            <div class="text-center my-4">
              <div class="mb-4">
                <BSpinner class="align-middle" />
              </div>
              <h5>Λήψη δεδομένων...</h5>
            </div>
          </template>

          <template v-slot:empty>
            <h5 class="mb-0 py-2 text-center">Δεν βρέθηκαν σελίδες</h5>
          </template>

          <template v-slot:emptyfiltered>
            <div class="mb-0 py-2 text-center">
              <h2 class="text-center mb-2">
                <BIcon icon="search" />
              </h2>
              Δεν βρέθηκαν αποτελέσματα αναζήτησης για "{{ searchTerm }}"
            </div>
          </template>
        </BTable>

        <ListPagination
          v-if="hasPagination"
          :total="total"
          :current-page="currentPage"
          :per-page="perPage"
          @select-size="handleSizeSelection"
          @select-page="handlePageSelection"
          class="mt-3"
        />
      </BCardBody>
    </BCard>
  </div>
</template>

<script>
import request from '@/utils/request';
import PaginationMixin from '@/mixins/PaginationMixin';
import ListPagination from '@/components/ListPagination';

export default {
  mixins: [PaginationMixin],

  components: {
    ListPagination,
  },

  data: () => ({
    sortBy: 'title',
    sortDesc: false,
    fields: ['#', { label: 'Όνομα', key: 'title', sortable: true }],
    breadcrumbItems: [
      {
        text: 'Αρχική',
        to: { name: 'home' },
      },
      {
        text: 'Στατικές Σελίδες',
        active: true,
      },
    ],
  }),

  methods: {
    async pagesProvider(ctx) {
      try {
        this.isLoading = true;

        const { data } = await request.get('/pages', {
          params: {
            page: ctx.currentPage,
            size: ctx.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            filter: ctx.filter,
          },
        });

        this.currentPage = data.pages.current_page;
        this.total = data.pages.total;
        this.items = data.pages;
        this.isLoaded = true;

        return this.items;
      } catch (err) {
        this.$router.push({ name: 'error' });
        this.$swal({
          title: 'Σφάλμα',
          text: 'Δεv μπόρεσαν να φορτωθούν οι σελίδες',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
