import request from '@/utils/request';

export default {
  data: () => ({
    eshopURL: process.env.VUE_APP_ESHOP_URL,
  }),

  computed: {
    canAddBrands() {
      return process.env.VUE_APP_CAN_ADD_BRANDS === 'true';
    },

    canDeleteBrands() {
      return process.env.VUE_APP_CAN_DELETE_BRANDS === 'true';
    },

    fullHrefPath() {
      return `${this.eshopURL}/${this.model.slug}`;
    },
  },

  methods: {
    handleDelete() {
      this.$swal({
        title: 'Είστε σίγουροι;',
        text: `Θέλετε να διαγράψετε τη μάρκα: ${this.model.title};`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ναι',
        cancelButtonText: 'Όχι',
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await request.delete(`/brands/${this.$route.params.id}`);

            this.$swal({
              title: 'Επιτυχία',
              text: `Η μάρκα ${this.model.title} διαγράφηκε επιτυχώς`,
              icon: 'success',
              timer: 3000,
            });

            this.$emit('get-counters');

            await this.$router.push({ name: 'brands.all' });
          } catch (error) {
            this.$swal({
              title: 'Σφάλμα',
              text: `Η μάρκα ${this.model.title} δεν διεγράφη`,
              icon: 'error',
              timer: 3000,
              showConfirmButton: false,
            });
          }
        }
      });
    },
  },
};
