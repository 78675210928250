
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Order, OrderStatus, OrderStatusSlug, SendMethodSlug } from '@/types';
import { BvEvent } from 'bootstrap-vue';

@Component
export default class OrdersStatusesItem extends Vue {
  @Prop({ type: Object, required: true }) readonly order!: Order;
  @Prop({ type: Object, required: true }) readonly status!: OrderStatus;
  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;
  @Prop({ type: Array, required: false }) readonly vouchers!: Array<string>;
  @Prop({ type: Function, required: true }) readonly onChangeStatus!: (
    e: BvEvent,
    status: OrderStatus,
  ) => void;

  get isFirst() {
    return this.status.order === 0;
  }

  get isVoucher() {
    return this.status.slug === OrderStatusSlug.Voucher;
  }

  get hasVouchers() {
    return this.vouchers.length > 0;
  }

  get isDisabled() {
    return this.disabled || this.isFirst;
  }

  get title() {
    const statusSlug = this.status.slug;
    const methodSlug = this.order.send_method?.slug;

    if (
      statusSlug === OrderStatusSlug.ForDelivery &&
      methodSlug == SendMethodSlug.StorePickup
    ) {
      return 'Έτοιμη για παραλαβή';
    } else {
      return this.status.title;
    }
  }
}
