<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <span class="mr-2">Εμφάνιση</span>

      <BFormSelect
        v-model="pageSize"
        :options="sizeOptions"
        size="sm"
        class="size-selection mr-2"
        @input="$emit('select-size', pageSize)"
      />
      απο&nbsp;<strong>{{ total | number }}</strong
      >&nbsp;εγγραφές
    </div>

    <BPagination
      pills
      v-model="page"
      :total-rows="total"
      :per-page="perPage"
      align="center"
      class="m-0"
      @input="$emit('select-page', page)"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    pageSize: 20,
    page: 1,
    sizeOptions: [20, 50, 100, 200],
  }),

  watch: {
    currentPage: {
      handler(newVal) {
        this.page = newVal || 1;
      },
      immediate: true,
    },

    perPage: {
      handler(newVal) {
        this.pageSize = newVal || 20;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selection {
  color: $gray-800;
  width: fit-content;
}

::v-deep .pagination {
  .page-item.disabled {
    .page-link {
      border: none;
      color: $gray-100;
    }
  }
}
</style>
