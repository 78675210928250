
import { Component, Vue } from 'vue-property-decorator';
import request from '@/utils/request';
import EditorMixin from '@/mixins/EditorMixin';
import { Barcode } from '@/types';
import Layout from './components/ProductsEditLayout.vue';
import ProductsEditMethodsMixin from './mixins/ProductsEditMethodsMixin';

@Component({
  components: {
    Layout,
  },
  mixins: [EditorMixin, ProductsEditMethodsMixin],
})
export default class ProductsEditBarcodes extends Vue {
  isLoading = false;
  isSaving = false;
  model = {};
  form: {
    barcodes: Partial<Barcode>[];
  } = {
    barcodes: [],
  };
  barcodes: Partial<Barcode>[] = [];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Προϊόντα',
      to: { name: 'products' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'products.edit.general' },
    },
    {
      text: 'Barcodes',
      active: true,
    },
  ];

  get hasNoBarcodes() {
    return !this.form?.barcodes || !this.form?.barcodes?.length;
  }

  get canEditProductBarcodes() {
    return process.env.VUE_APP_CAN_EDIT_PRODUCT_BARCODES === 'true';
  }

  get canAddProductBarcodes() {
    return process.env.VUE_APP_CAN_ADD_PRODUCT_BARCODES === 'true';
  }

  async created() {
    try {
      this.isLoading = true;
      const [barcodesData, productData] = await Promise.all([
        request.get(`/products/${this.$route.params.id}/barcodes`),
        request.get(`/products/${this.$route.params.id}`),
      ]);

      this.barcodes = barcodesData.data.barcodes;
      this.model = productData.data.product;
      this.form = {
        barcodes: this.barcodes,
      };
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν τα barcodes',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleForm() {
    try {
      this.isSaving = true;
      const data = {
        ...this.form,
        barcodes: this.form.barcodes
          .filter(barcode => barcode.code)
          .map(barcode => barcode.code),
      };
      await request.put(`/products/${this.$route.params.id}/barcodes`, data);

      this.$swal({
        title: 'Επιτυχία',
        text: 'Τα barcodes του προϊόντος ενημερώθηκαν',
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
      });

      this.$emit('get-counters');
    } catch (err) {
      const { status, data } = err.response;
      if (status === 400) {
        (this.$refs.form as HTMLFormElement).setErrors(data.messages);
      }

      this.$swal({
        title: 'Σφάλμα',
        text: 'Τα barcodes του προϊόντος δεν ενημερώθηκαν',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isSaving = false;
    }
  }

  handleReset() {
    this.form = {
      barcodes: this.barcodes,
    };
    this.$nextTick(() => {
      this.$refs.form && (this.$refs.form as HTMLFormElement).reset();
    });
  }

  handleAddBarcode() {
    this.form = {
      ...this.form,
      barcodes: [
        ...this.form.barcodes,
        {
          code: '',
        },
      ],
    };
  }

  handleDeleteBarcode(index: number) {
    this.form = {
      ...this.form,
      barcodes: this.form.barcodes.filter(
        (item: Partial<Barcode>, pos: number) => pos !== index,
      ),
    };
  }

  handleCopySuccess() {
    this.$swal({
      toast: true,
      title: 'Επιτυχία',
      text: 'Η αντιγραφή στο clipboard έγινε επιτυχώς!',
      icon: 'success',
      timer: 3000,
      showConfirmButton: false,
      position: 'bottom-end',
    });
  }
}
