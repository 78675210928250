
import { Component, Vue, Prop } from 'vue-property-decorator';
import { omit } from 'lodash';
import qs from 'qs';
import { BTable } from 'bootstrap-vue';
import request from '@/utils/request';
import QueryParamsPaginationMixin from '@/mixins/QueryParamsPaginationMixin';
import ListPagination from '@/components/ListPagination.vue';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';
import ListActionsBar from '@/components/ListActionsBar.vue';
import OrdersListStatus from '@/components/OrdersListStatus.vue';
import Layout from './components/OrdersLayout.vue';
import { Order } from '@/types';

@Component({
  mixins: [QueryParamsPaginationMixin],
  components: {
    ListThreeDotsMenu,
    ListPagination,
    ListActionsBar,
    OrdersListStatus,
    Layout,
  },
})
export default class OrdersList extends Vue {
  @Prop({ type: Object, required: false }) readonly counters!: Object;
  @Prop({ type: Boolean, required: false }) readonly isCountersLoaded!: boolean;

  sortBy = 'created_at';
  sortDesc = true;
  fields = [
    { label: '', key: 'checkbox', class: 'text-center' },
    { label: 'Τύπος', key: 'type', class: 'text-center' },
    { label: 'Κωδικός', key: 'id', class: 'text-center' },
    {
      label: 'Ημερομηνία',
      key: 'created_at',
      sortable: true,
      class: 'text-center',
    },
    { label: 'Πελάτης', key: 'customer.name', class: 'text-center' },
    { label: 'Τρόπος Πληρωμής', key: 'checkoutMethod', class: 'text-center' },
    { label: 'Τρόπος Αποστολής', key: 'sendMethod', class: 'text-center' },
    { label: 'Μέθοδος παραγγελίας', key: 'orderMethod', class: 'text-center' },
    {
      label: 'Τεμάχια',
      key: 'count',
      class: 'text-center',
    },
    {
      label: 'Αξία (€)',
      key: 'sum_total',
      class: 'text-center',
    },
    { label: 'Κατάσταση', key: 'status.title', class: 'text-center' },
    { label: '', key: 'actions', class: 'text-center' },
  ];
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Παραγγελίες',
      active: true,
    },
  ];
  filters = [
    {
      label: 'Έχει έξοδα αποστολής',
      apiName: 'has_send_charge',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Έχει έξοδα πληρωμής',
      apiName: 'has_checkout_charge',
      type: 'boolean',
      value: false,
    },
    {
      label: 'Έχει κουπόνι',
      apiName: 'has_coupon',
      type: 'boolean',
      value: false,
    },
  ];
  isLoading = false;
  isLoaded = false;
  total = 0;
  items = [];
  searchTerm = '';
  selectedIds = [];

  get currentPage() {
    return Number(this.$route.query.page);
  }

  get numberPerPage() {
    return Number(this.$route.query.perPage);
  }

  get getFilters() {
    return {
      ...omit(this.$route.query, ['page', 'perPage']),
      filter: this.searchTerm,
    };
  }

  async ordersProvider(ctx: any) {
    try {
      this.isLoading = true;

      const { data } = await request.get('/orders', {
        params: {
          page: this.$route.query.page,
          size: this.$route.query.perPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
          tab: 'withdrew',
          ...ctx.filter,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'comma',
            encode: false,
          });
        },
      });

      this.total = data.orders.total;
      this.items = data.orders.data;
      this.isLoaded = true;
      this.$emit('get-counters');
      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δε μπόρεσαν να φορτωθούν οι παραγγελίες',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleCancel(item: Order) {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ακυρώσετε την παραγγελία: ${item.code};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/orders/${item.id}`);

          this.$emit('get-counters');

          this.$swal({
            title: 'Επιτυχία',
            text: `H παραγγελία ${item.code} ακυρώθηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Η παραγγελία ${item.code} δεν ακυρώθηκε`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  handleMassCancel() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να ακυρώσετε ${
        this.selectedIds.length === 1
          ? 'την επιλεγμένη παραγγελία;'
          : `τις επιλεγμένες ${this.selectedIds.length} παραγγελίες;`
      }`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete('/orders', {
            params: {
              ids: this.selectedIds,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: 'comma',
                encode: false,
              });
            },
          }),
            this.$swal({
              title: 'Επιτυχία',
              text: `${
                this.selectedIds.length === 1
                  ? 'H παραγγελία ακυρώθηκε επιτυχώς'
                  : `${this.selectedIds.length} παραγγελίες ακυρώθηκαν επιτυχώς`
              }`,
              icon: 'success',
              timer: 3000,
              showConfirmButton: false,
            });

          this.$nextTick(() => {
            this.$refs.table && (this.$refs.table as BTable).refresh();
          });
          this.$emit('get-counters');
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `${
              this.selectedIds.length === 1
                ? 'Η παραγγελία δεν ακυρώθηκε'
                : 'Οι παραγγελίες δεν ακυρώθηκαν'
            }`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }
}
