
import { Vue, Component } from 'vue-property-decorator';

import request from '@/utils/request';
import ProductThumbnail from '@/components/ProductThumbnail.vue';
import Layout from '@/views/Dashboard/views/Customers/CustomersEdit/components/CustomersEditLayout.vue';
import { Product, Customer } from '@/types';
import ListThreeDotsMenu from '@/components/ListThreeDotsMenu.vue';

@Component({
  components: {
    ListThreeDotsMenu,
    Layout,
    ProductThumbnail,
  },
})
export default class CustomersEditProducts extends Vue {
  isSaving = false;
  isLoading = false;
  breadcrumbItems = [
    {
      text: 'Αρχική',
      to: { name: 'home' },
    },
    {
      text: 'Πελάτες',
      to: { name: 'customers' },
    },
    {
      text: 'Επεξεργασία',
      to: { name: 'customers.edit.general' },
    },
    {
      text: 'Προϊόντα',
      active: true,
    },
  ];
  fields = [
    '#',
    { label: 'Φώτο', key: 'thumbnail', class: 'text-center' },
    { label: 'Όνομα', key: 'title', sortable: true, class: 'text-center' },
    { label: 'Stock', key: 'stock', class: 'text-center' },
    { label: 'Barcodes', key: 'barcodes', class: 'text-center' },
    {
      label: 'Π.Λ Τιμή (€)',
      key: 'sell_price',
      sortable: true,
      class: 'text-center',
    },
    {
      label: 'Έκπτωση (%)',
      key: 'discount',
      sortable: true,
      class: 'text-center',
    },
    {
      label: 'Τελική τιμή (€)',
      key: 'final_price',
      sortable: true,
      class: 'text-center',
    },
    {
      label: 'Προβολές',
      key: 'views_count',
      sortable: true,
      class: 'text-center',
    },
    {
      label: 'Πωλήσεις',
      key: 'purchases_count',
      sortable: true,
      class: 'text-center',
    },
    { label: 'Ενεργό', key: 'active', class: 'text-center' },
    { label: '', key: 'actions', class: 'text-center' },
  ];
  items: Product[] = [];
  customer: Customer | null = null;

  async productsProvider() {
    try {
      this.isLoading = true;

      const { data } = await request.get(
        `/customers/${this.$route.params.id}/products/`,
      );
      const { data: customerData } = await request.get(
        `/customers/${this.$route.params.id}`,
      );
      this.customer = customerData.customer;
      this.items = data.products;

      return this.items;
    } catch (err) {
      this.$router.push({ name: 'error' });
      this.$swal({
        title: 'Σφάλμα',
        text: 'Δεv μπόρεσαν να φορτωθούν προϊόντα',
        icon: 'error',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleDelete() {
    this.$swal({
      title: 'Είστε σίγουροι;',
      text: `Θέλετε να διαγράψετε τον πελάτη: ${this.customersName};`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ναι',
      cancelButtonText: 'Όχι',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await request.delete(`/customers/${this.$route.params.id}`);

          this.$swal({
            title: 'Επιτυχία',
            text: `Ο πελάτης ${this.customersName} διαγράφηκε επιτυχώς`,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          });

          await this.$router.push({ name: 'customers.all' });
        } catch (error) {
          this.$swal({
            title: 'Σφάλμα',
            text: `Ο πελάτης ${this.customersName} δεν διεγράφη`,
            icon: 'error',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    });
  }

  get customersType() {
    return this.customer?.type;
  }

  get customersName() {
    return this.customer?.fullName ?? '';
  }
}
